import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { TransactionResponse } from '@ethersproject/providers'
import {
  Alert,
  alertVariants,
  Box,
  Button,
  CardBody,
  CopyIcon,
  Flex,
  IconButton,
  Input,
  Text,
} from '@pancakeswap/uikit'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import styled from 'styled-components'
import { AutoColumn } from '../../components/Layout/Column'
import { AppBody } from '../../components/App'
import { useIdoContract, useTokenContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../../state/multicall/hooks'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline-block' : 'none')};
  position: absolute;
  padding: 8px;
  top: -38px;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
  width: 100px;
`

export default function Ido({
  match: {
    params: { inviter },
  },
  history,
}: RouteComponentProps<{ inviter?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const { t } = useTranslation()

  const [roundIdo, setRoundIdo] = useState(1)
  const [valueInput, setValueInput] = useState('100')
  const [rawValue, setRawValue] = useState(100e18)
  const usdt = {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0x7afd064DaE94d73ee37d19ff2D264f5A2903bBB0',
  }

  const ido = useIdoContract(true)
  const token = useTokenContract(usdt[chainId])
  const roundInfo = useSingleCallResult(ido, 'roundInfo')?.result?._roundInfo
  const inputs1 = useMemo(() => [account], [account])
  const userInfo = useSingleCallResult(ido, 'getUserInfo', inputs1)?.result?._userInfo
  const senders = useMemo(() => [account], [account])
  const isWhiteList: any = useSingleCallResult(ido, 'isWhiteList', senders)?.result

  const inputs = useMemo(() => [account, ido.address], [account, ido.address])
  const approvedValue = useSingleCallResult(token, 'allowance', inputs).result

  const openClaim: any = useSingleCallResult(ido, 'openClaim')?.result

  const zeroAddr = '0x0000000000000000000000000000000000000000'
  // mark when a user has submitted an approval, reset onTokenSelection for input field

  const [claimValue, setClaimValue] = useState('0')
  const [canClaim, setCanClaim] = useState(false)

  useMemo(() => {
    if (!userInfo) {
      return
    }
    const buyValue = userInfo.buyValue
    const claimedValue = userInfo.claimedValue
    const _raleValue = new BigNumber(buyValue.toString()).minus(claimedValue.toString()).div(1e18)
    setClaimValue(_raleValue.toString())
  }, [userInfo])

  useMemo(() => {
    // console.log(roundInfo)
    if (roundInfo?.index) {
      setRoundIdo(roundInfo?.index?.toNumber())
      // console.log(roundIdo)
    }
  }, [roundInfo])

  const handlerToPay = useCallback(async () => {
    if (!account || !chainId || !library) {
      return
    }
    try {
      const value = new BigNumber(valueInput).times(1e18)
      const _approvedValue = approvedValue
      if (BigNumber.isBigNumber(_approvedValue) && approvedValue.lt(value)) {
        const tx: TransactionResponse = await token.approve(
          ido.address,
          '115792089237316195423570985008687907853269984665640564039457584007913129639935',
        )
        const result = await tx.wait()
        if (result.status !== 1) {
          return
        }
      } else if (new BigNumber(_approvedValue.toString()).lt(value)) {
        const tx: TransactionResponse = await token.approve(
          ido.address,
          '115792089237316195423570985008687907853269984665640564039457584007913129639935',
        )
        const result = await tx.wait()
        if (result.status !== 1) {
          return
        }
      }
      // const gasLimit: BigNumber | void = await ido.estimateGas["deposit"](...payArgs)
      const tx = await ido.deposit(inviter ?? zeroAddr, value.toString())
      const result = await tx.wait()
      if (result.status === 1) {
        console.log('buySuccess')
      } else {
        console.log('buy failed')
      }
    } catch (e) {
      console.log('error')

      console.log(e)
      if (e.data?.message) {
        console.log(e.data?.message)
      }
    }
  }, [account, chainId, library, valueInput, approvedValue, ido, inviter, token])
  const handlerToClaim = useCallback(async () => {
    if (!account || !chainId || !library) {
      return
    }
    try {
      // const gasLimit: BigNumber | void = await ido.estimateGas["deposit"](...payArgs)
      const tx = await ido.claim()
      const result = await tx.wait()
      if (result.status === 1) {
        console.log('claim Success')
      } else {
        console.log('claim failed')
      }
    } catch (e) {
      console.log('error')

      console.log(e)
      if (e.data?.message) {
        console.log(e.data?.message)
      }
    }
  }, [account, chainId, library, ido])
  const rule = {
    0: '仅限白名单团队长参与,此轮次仅可购买一次, 单价1.5U/个',
    1: '此轮次仅可购买一次, 单价2U/个',
    2: '单价3U/个',
  }

  function parseCustomValue(value: string) {
    if (value === '') {
      setValueInput('')
    } else {
      const newValue = Number.parseFloat(value).toFixed(0)
      setValueInput(newValue)
    }

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 1e18).toString())
      if (roundIdo > 1) {
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat >= 3e18) {
          setRawValue(valueAsIntFromRoundedFloat)
        } else {
          setRawValue(0)
        }
      } else if (roundIdo === 0) {
        if (
          !Number.isNaN(valueAsIntFromRoundedFloat) &&
          valueAsIntFromRoundedFloat >= 200e18 &&
          valueAsIntFromRoundedFloat <= 500e18
        ) {
          setRawValue(valueAsIntFromRoundedFloat)
        } else if (valueAsIntFromRoundedFloat > 500e18) {
          setRawValue(500e18)
        } else {
          setRawValue(200e18)
        }
      } else if (roundIdo === 1) {
        setRawValue(100e18)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // useMemo(() => {
  //   if (roundIdo === 0) {
  //
  //     if (valueInput === '') {
  //
  //       setValueInput('200')
  //       setRawValue(200e18)
  //     }
  //   } else if (roundIdo === 1) {
  //     if (valueInput === '') {
  //       setValueInput('100')
  //       setRawValue(100e18)
  //     }
  //
  //   } else if (valueInput === '') {
  //       setValueInput('')
  //       setRawValue(0)
  //
  //   }
  // }, [roundIdo, valueInput])
  const [formatInviteUrl, setFormatInviteUrl] = useState(null)
  useEffect(() => {
    if (!account) {
      return
    }
    const u = `https://www.aplanet.cc/ido/${account}`
    setFormatInviteUrl(u)
  }, [account])

  useEffect(() => {
    if (openClaim) {
      setCanClaim(openClaim[0])
    }
  }, [openClaim, canClaim])

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  function displayTooltip() {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
  }

  const copyAddress = () => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(formatInviteUrl).then(() => displayTooltip())
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = formatInviteUrl
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '100px',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <AppBody>
        <Box pt="20px">
          <Text textAlign="center" fontSize="20px">
            IDO
          </Text>
          <Box height="20px" />
          <Text textAlign="center" fontSize="16px">
            第 {roundIdo + 1} 轮
          </Text>
          <Box height="20px" />
          <Text textAlign="center" fontSize="14px">
            IDO 已结束
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '30px',
              padding: '10px',
            }}
          >
            <Box width="100%">
              <Flex>
                <Input
                  readOnly
                  scale="lg"
                  pt="10px"
                  placeholder="每份100USDT"
                  value={valueInput}
                  onBlur={() => {
                    parseCustomValue((rawValue / 1e18).toFixed(0))
                  }}
                  onChange={(e) => parseCustomValue(e.target.value)}
                  // readOnly={roundIdo === 1}
                />
                <Flex alignItems="center" pl="5px">
                  USDT
                </Flex>
              </Flex>
              <Flex justifyContent="center" pt="20px">
                <Button onClick={handlerToPay} disabled>
                  支付
                </Button>
              </Flex>
              <Flex alignItems="center" pt="20px">
                <Text>可提取：{claimValue}</Text>
                <Box style={{ flex: 1 }} />
                {canClaim ? <Button onClick={handlerToClaim}>提取</Button> : <Button disabled>提取</Button>}
              </Flex>
              <Text>专属邀请链接：</Text>
              <div style={{ whiteSpace: 'pre-wrap' }}>{formatInviteUrl}</div>
              {isWhiteList && isWhiteList[0] === true ? (
                <Flex justifyContent="center">
                  <IconButton variant="text" onClick={copyAddress}>
                    <Flex alignItems="center">
                      <div style={{ width: '40px' }}>复制</div>
                      <CopyIcon color="primary" width="24px" />
                    </Flex>
                  </IconButton>
                </Flex>
              ) : (
                <div />
              )}
            </Box>
          </div>
        </Box>
        <CardBody>
          <AutoColumn gap="20px" />
        </CardBody>
      </AppBody>
    </div>
  )
}
